import { Box, ServiceTypeName } from "@enerbit/base";
import {
  HistoryDialogElectricitySupplyServiceContainer,
  HistoryDialogEnerbitServicesContainer,
  HistoryDialogFixedComponentContainer,
  HistoryDialogThirdPartyServicesContainer,
} from "../pages/invoices-modals/Components/modal-invoice-information/dialogs/history-dialog/history-dialog-settlement/history-dialog-settlement-container";

export const getDialogComponentByName = (
  serviceTypeName: string,
  invoiceId: string
): JSX.Element => {
  if (serviceTypeName === ServiceTypeName.electricitySupplyServices) {
    return (
      <HistoryDialogElectricitySupplyServiceContainer invoiceId={invoiceId} />
    );
  } else if (
    serviceTypeName === ServiceTypeName.fixedEnerbitElectricitySupplyServices
  ) {
    return <HistoryDialogFixedComponentContainer invoiceId={invoiceId} />;
  } else if (
    serviceTypeName === ServiceTypeName.enerbitElectricitySupplyServices
  ) {
    return <HistoryDialogEnerbitServicesContainer invoiceId={invoiceId} />;
  } else if (serviceTypeName === ServiceTypeName.billerbitStreetLight) {
    return <HistoryDialogThirdPartyServicesContainer invoiceId={invoiceId} />;
  }else if (serviceTypeName === ServiceTypeName.billerbitSecurity) {
    return <HistoryDialogThirdPartyServicesContainer invoiceId={invoiceId} />;
  }
  return <Box></Box>;
};
