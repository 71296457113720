import { useDispatch, useSelector } from "react-redux";
import { HistoryDialogProps } from "../../../../../../../../models/props/HistoryDialog";
import { AppDispatch } from "../../../../../../../../store/store";

import { Box, serviceName } from "@enerbit/base";
import { useEffect } from "react";
import { StateStorage } from "../../../../../../../../models/stateStorage";
import { getInvoiceDetailThirdPartyServices } from "../../../../../../../../store/actions/invoicesModals/invoicesModals.actions";

import { setOpenHistoryDialog } from "../../../../../../../../store/slices/invoicesModalsSlice";
import { lowercaseFirstLetter } from "../../../../../../../../utils/titleUtils";
import { ThirdPartyServicesContainer } from "../../../../settlement/containers/ThirdPartyServicesContainer";
import { DetailSettlementLeft } from "../../../details";
import HistoryDialogThirdPartyServicesHeader from "../history-dialog-settlement-header/HistoryDialogThirdPartyServicesHeader";

//Componente Modal que contiene la Tabla del Historial

export const HistoryDialogThirdPartyServicesContainer = ({
  invoiceId,
}: HistoryDialogProps) => {
  const { thirdPartyServices } = useSelector(
    (state: StateStorage) => state.invoicesModals
  );
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    if (invoiceId) {
      dispatch(getInvoiceDetailThirdPartyServices(invoiceId));
    }
    dispatch(setOpenHistoryDialog(true));
  }, []);

  return (
    <div>
      {thirdPartyServices ? (
        <HistoryDialogThirdPartyServicesHeader
          title={`Detalle liquidación factura ${lowercaseFirstLetter(
            serviceName[
              thirdPartyServices.invoice
                ?.service_type_name as keyof typeof serviceName
            ]
          )}`}
          showButtonBilling={true}
          detailLeft={
            <DetailSettlementLeft
              issuedAt={thirdPartyServices.invoice?.issued_at}
            />
          }
          content={
            <ThirdPartyServicesContainer invoice={thirdPartyServices.invoice} />
          }
        />
      ) : (
        <Box></Box>
      )}
    </div>
  );
};
